import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/vibhanasheen.png'
import img1 from '../Assets/vibha.png'

const VibhaNasheen = () => {
    return (
        <div>
            <Header />
            <div className="vibha-background">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <img className="img-fluid header-image" src={img} />
                </div>
            </div>
        </div>
        <div className="gap-1"></div>
		<div className="container-fluid">
			<div className="row text-center">
				<div className="col-sm-12 col-md-12 col-lg-12">
					<h1 className="display-6">VIBHA NASHEEN</h1>
				</div>
			</div>
			<div className="gap-1"></div>
			<div className="row">
				<div className="col-sm-6 col-md-6 col-lg-6">
					<p align="justify">Vibha is the founder of HAEGO Foundation, an organization that is dedicated to bring about awareness in the society regarding mindfulness, emotional healing and wellness amongst all ages of people. </p>
                    <p align="justify">Apart from being an MBA in Marketing Management and a telecom professional for over 21 years, Vibha is also a motivational speaker, an emotional healer, authoress of 2 books, Life Coach, LOA/EFT Practitioner, Quora Contributor, Counseller - Chai Tumhari, Waqt Mera, a theater artist and a biker.</p>
                </div>
				<div className="col-sm-6 col-md-6 col-lg-6 text-center">
					<img className="img-fluid vert-move" src={img1} />
				</div>
			</div>
		</div>
        </div>
            <Footer />
        </div>
    )
}

export default VibhaNasheen