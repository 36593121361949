import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/news.jpg'
import img1 from '../Assets/1.jpg'

const News = () => {
    return (
        <div>
            <Header />
            <div class="container-fluid">
                <div class="row mt-4">
                    <div class="item col-sm-6 col-md-3 mb-3 bg-light pt-2">
                        <a href={img} class="fancybox" data-fancybox="gallery1">
                            <img src={img} width="100%" class="img-fluid height_set border" />
                        </a>
                        <h5 class='mt-3'>Acting Workshop at Chandigarh</h5>
                    </div>

                    <div class="item col-sm-6 col-md-3 mb-3 bg-light pt-2">
                        <a href={img1} class="fancybox" data-fancybox="gallery1">
                            <img src={img1} width="100%" class="img-fluid height_set border" />
                        </a>
                        <h5 class='mt-3'>Mind Body And Soul Workshop at Gwalior</h5>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default News