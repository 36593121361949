import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/events/independence-day-contest-2022-landscape.jpg'
import img1 from '../Assets/events/independence-day-contest-2022-portrait.jpg'
import img2 from '../Assets/events/painting.png'
import img3 from '../Assets/events/slogan.png'
import img4 from '../Assets/events/poetry.png'
import img5 from '../Assets/events/fancy-dress.png'
import img6 from '../Assets/events/patriotic.png'
import img7 from '../Assets/events/face-painting-2.png'

const Independance = () => {
    return (
        <div>
            <Header />
            <div className="bg-light">
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={img} alt="main banner" className="img-fluid w-100 d-none d-md-block" style={{ objectFit: "cover", height: "70vh" }} />
                        </div>
                    </div>
                </div>
                <form
                // action="register.php" method="POST"
                >
                    <div className="container-fluid bg-light">
                        <img src={img1} alt="main banner" className="img-fluid w-100 d-block d-md-none" />
                    </div>
                    <div className="bg-light p-3">
                        <div className="container">
                            <div className="section2 mt-2">
                                <p className="fs-4 fw-bold">Inviting all our lovely kids to the 75th Independence Day Celebration Contest.</p>
                                <hr />
                            </div>
                            <div className="section2 mt-2">
                                <p className="fs-4 fw-bold">Contest Categories</p>
                                <div className="row my-3">
                                    <div className="col-md-4">
                                        <div className="card shadow">
                                            <img src={img2} className="card-img-top" style={{ width: "100%",height:'auto' }} />
                                            <div className="card-body text-center">
                                                <p><strong>Drawing Competition</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 card shadow">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h3 className="display-6">Drawing Competition</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 my-1">
                                                <p align="justify">
                                                        Participants can share patriotic songs in Hindi or English language. Record your song video (max length 4 mins) and share your entries to
                                                        <a href="mailto:vibha@sartracglobal.com" style={{ textDecoration: "none" }}>{' '}vibha@sartracglobal.com</a> or
                                                        <img src="https://www.freepnglogos.com/uploads/whatsapp-logo-light-green-png-0.png" style={{ width: "5%", verticalAlign: "middle" }} alt="WhatsApp" />
                                                        <a href="https://wa.me/919891005929" style={{ textDecoration: "none" }}>989 100 5929</a>
                                                    </p>

                                                    <div className="my-0">
                                                        <ul>
                                                            <li>Theme: Independance Day</li>
                                                            <li>Group A: Age 3 - 6 years</li>
                                                            <li>Group B: Age 7 - 11 years</li>
                                                            <li>Group C: Age 12 - 15 years</li>
                                                            <li>Registration fees: Rs. 250/- per child per event. </li>
                                                            <li>E-certificate for participation for all.</li>
                                                            <li>Winners will get trophy. </li>
                                                            <li>Best entries will be displayed on all our social media platforms.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="my-3">
                                                        <a className="btn btn-primary" href="https://forms.gle/4vp2deLWEtz41QjY6">Register Here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-md-8 card shadow">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h3 className="display-6">Slogan Competition</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 my-1">
                                                <p align="justify">
                                                        Participants can share patriotic songs in Hindi or English language. Record your song video (max length 4 mins) and share your entries to
                                                        <a href="mailto:vibha@sartracglobal.com" style={{ textDecoration: "none" }}>{' '}vibha@sartracglobal.com</a> or
                                                        <img src="https://www.freepnglogos.com/uploads/whatsapp-logo-light-green-png-0.png" style={{ width: "5%", verticalAlign: "middle" }} alt="WhatsApp" />
                                                        <a href="https://wa.me/919891005929" style={{ textDecoration: "none" }}>989 100 5929</a>
                                                    </p>

                                                    <div className="my-0">
                                                        <ul>
                                                            <li>Theme: Independance Day</li>
                                                            <li>Group A: Age 3 - 6 years</li>
                                                            <li>Group B: Age 7 - 11 years</li>
                                                            <li>Group C: Age 12 - 15 years</li>
                                                            <li>Registration fees: Rs. 250/- per child per event. </li>
                                                            <li>E-certificate for participation for all.</li>
                                                            <li>Winners will get trophy. </li>
                                                            <li>Best entries will be displayed on all our social media platforms.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="my-3">
                                                        <a className="btn btn-primary" href="https://forms.gle/4vp2deLWEtz41QjY6">Register Here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card shadow">
                                            <img src={img3} className="card-img-top" style={{ width: "100%",height:'auto' }} />
                                            <div className="card-body text-center">
                                                <p><strong>Slogan Competition</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-4">
                                        <div className="card shadow">
                                            <img src={img4} className="card-img-top" style={{ width: "100%",height:'auto' }} />
                                            <div className="card-body text-center">
                                                <p><strong>Poem Recitation Competition</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 card shadow">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h3 className="display-6">Poem Recitation Competition</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 my-2">
                                                <p align="justify">
                                                        Participants can share patriotic songs in Hindi or English language. Record your song video (max length 4 mins) and share your entries to
                                                        <a href="mailto:vibha@sartracglobal.com" style={{ textDecoration: "none" }}>{' '}vibha@sartracglobal.com</a> or
                                                        <img src="https://www.freepnglogos.com/uploads/whatsapp-logo-light-green-png-0.png" style={{ width: "5%", verticalAlign: "middle" }} alt="WhatsApp" />
                                                        <a href="https://wa.me/919891005929" style={{ textDecoration: "none" }}>989 100 5929</a>
                                                    </p>

                                                    <div className="my-0">
                                                        <ul>
                                                            <li>Theme: Independance Day</li>
                                                            <li>Group A: Age 3 - 6 years</li>
                                                            <li>Group B: Age 7 - 11 years</li>
                                                            <li>Group C: Age 12 - 15 years</li>
                                                            <li>Registration fees: Rs. 250/- per child per event. </li>
                                                            <li>E-certificate for participation for all.</li>
                                                            <li>Winners will get trophy. </li>
                                                            <li>Best entries will be displayed on all our social media platforms.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="my-3">
                                                        <a className="btn btn-primary" href="https://forms.gle/4vp2deLWEtz41QjY6">Register Here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-md-8 card shadow">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h3 className="display-6">Fancy Dress Competition</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 my-2">
                                                <p align="justify">
                                                        Participants can share patriotic songs in Hindi or English language. Record your song video (max length 4 mins) and share your entries to
                                                        <a href="mailto:vibha@sartracglobal.com" style={{ textDecoration: "none" }}>{' '}vibha@sartracglobal.com</a> or
                                                        <img src="https://www.freepnglogos.com/uploads/whatsapp-logo-light-green-png-0.png" style={{ width: "5%", verticalAlign: "middle" }} alt="WhatsApp" />
                                                        <a href="https://wa.me/919891005929" style={{ textDecoration: "none" }}>989 100 5929</a>
                                                    </p>

                                                    <div className="my-0">
                                                        <ul>
                                                            <li>Theme: Independance Day</li>
                                                            <li>Group A: Age 3 - 6 years</li>
                                                            <li>Group B: Age 7 - 11 years</li>
                                                            <li>Group C: Age 12 - 15 years</li>
                                                            <li>Registration fees: Rs. 250/- per child per event. </li>
                                                            <li>E-certificate for participation for all.</li>
                                                            <li>Winners will get trophy. </li>
                                                            <li>Best entries will be displayed on all our social media platforms.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="my-3">
                                                        <a className="btn btn-primary" href="https://forms.gle/4vp2deLWEtz41QjY6">Register Here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card shadow">
                                            <img src={img5} className="card-img-top" style={{ width: "100%",height:'auto' }} />
                                            <div className="card-body text-center">
                                                <p><strong>Fancy Dress Competition</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-4">
                                        <div className="card shadow">
                                            <img src={img6} className="card-img-top" style={{ width: "100%",height:'auto' }} />
                                            <div className="card-body text-center">
                                                <p><strong>Patriotic Song Competition</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 card shadow">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h3 className="display-6">Patriotic Song Competition</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 my-2">
                                                    <p align="justify">
                                                        Participants can share patriotic songs in Hindi or English language. Record your song video (max length 4 mins) and share your entries to
                                                        <a href="mailto:vibha@sartracglobal.com" style={{ textDecoration: "none" }}>{' '}vibha@sartracglobal.com</a> or
                                                        <img src="https://www.freepnglogos.com/uploads/whatsapp-logo-light-green-png-0.png" style={{ width: "5%", verticalAlign: "middle" }} alt="WhatsApp" />
                                                        <a href="https://wa.me/919891005929" style={{ textDecoration: "none" }}>989 100 5929</a>
                                                    </p>

                                                    <div className="my-0">
                                                        <ul>
                                                            <li>Theme: Independance Day</li>
                                                            <li>Group A: Age 3 - 6 years</li>
                                                            <li>Group B: Age 7 - 11 years</li>
                                                            <li>Group C: Age 12 - 15 years</li>
                                                            <li>Registration fees: Rs. 250/- per child per event. </li>
                                                            <li>E-certificate for participation for all.</li>
                                                            <li>Winners will get trophy. </li>
                                                            <li>Best entries will be displayed on all our social media platforms.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="my-3">
                                                        <a className="btn btn-primary" href="https://forms.gle/4vp2deLWEtz41QjY6">Register Here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-md-8 card shadow">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h3 className="display-6">Face Painting Selfie</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 my-2">
                                                <p align="justify">
                                                        Participants can share patriotic songs in Hindi or English language. Record your song video (max length 4 mins) and share your entries to
                                                        <a href="mailto:vibha@sartracglobal.com" style={{ textDecoration: "none" }}>{' '}vibha@sartracglobal.com</a> or
                                                        <img src="https://www.freepnglogos.com/uploads/whatsapp-logo-light-green-png-0.png" style={{ width: "5%", verticalAlign: "middle" }} alt="WhatsApp" />
                                                        <a href="https://wa.me/919891005929" style={{ textDecoration: "none" }}>989 100 5929</a>
                                                    </p>

                                                    <div className="my-0">
                                                        <ul>
                                                            <li>Theme: Independance Day</li>
                                                            <li>Group A: Age 3 - 6 years</li>
                                                            <li>Group B: Age 7 - 11 years</li>
                                                            <li>Group C: Age 12 - 15 years</li>
                                                            <li>Registration fees: Rs. 250/- per child per event. </li>
                                                            <li>E-certificate for participation for all.</li>
                                                            <li>Winners will get trophy. </li>
                                                            <li>Best entries will be displayed on all our social media platforms.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="my-3">
                                                        <a className="btn btn-primary" href="https://forms.gle/4vp2deLWEtz41QjY6">Register Here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card shadow">
                                            <img src={img7} className="card-img-top" style={{ width: "100%",height:'auto' }} />
                                            <div className="card-body text-center">
                                                <p><strong>Face Painting Selfie</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="container">
                            <div className="row">
                                <h1 className="display-6">
                                    Please Note:
                                </h1>
                                <p>
                                    <ul>
                                        <li>Winners will be judged by our panel of judges</li>
                                        <li>Last date of entry: 15th August 2022</li>
                                        <li>Result declaration: 31st August 2022</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>



            <Footer />
        </div>
    )
}

export default Independance