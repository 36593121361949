import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/maheshsharma.png'
import img1 from '../Assets/mahesh.png'

const MaheshSharma = () => {
  return (
    <div>
        <Header/>
        <div class="mahesh-background">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <img class="img-fluid header-image" src={img} />
                </div>
            </div>
        </div>
        <div class="gap-1"></div>
		<div class="container-fluid">
			<div class="row text-center">
				<div class="col-sm-12 col-md-12 col-lg-12">
					<h1 class="display-6">MAHESH SHARMA</h1>
				</div>
			</div>
			<div class="gap-1"></div>
			<div class="row">
				<div class="col-sm-6 col-md-6 col-lg-6">
					<p align="justify">Mahesh Sharma is an internationally certified LIFE Purpose Coach and workshop felicitator by profession and Humanist, researcher and nature enthusiast by choice. </p>
                    <p align="justify">He is the Founder and Chief Mentor at Know Your True Self Research Academy, which is a coaching, education and community sharing entity in the field of human inherent behaviour, emotional wellness and spiritual upliftment.</p>
                    <p align="justify">He is among the pioneer promoters of emotional health and well-being in the country since 2005 and moderator and initiator of MEHAC - Mental and Emotional Health Awareness Campaign</p>
				</div>
				<div class="col-sm-6 col-md-6 col-lg-6 text-center">
					<img class="img-fluid vert-move" src={img1} />
				</div>
			</div>
		</div>
        </div>
        <Footer/>
    </div>
  )
}

export default MaheshSharma