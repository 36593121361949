import React from 'react'
import img1 from '../Assets/sg1.png'
import img2 from '../Assets/sg2.png'
import img3 from '../Assets/sg3.png'
import img4 from '../Assets/sg4.png'
import img5 from '../Assets/sg5.png'
import img6 from '../Assets/sg6.png'
import img7 from '../Assets/sg7.png'
import img8 from '../Assets/sg8.png'
import img9 from '../Assets/sg9.png'
import img10 from '../Assets/sg10.png'
import img11 from '../Assets/sg11.png'
import img12 from '../Assets/sg12.png'

const Workshop = () => {
    return (
        <div><div className="text-center">
            <h1 className="display-6"> WORKSHOPS </h1>
        </div>
            <div className="gap-1"></div>
            <div className="container">
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel" data-pause="false">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-3"><img src={img1} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img2} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img3} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img4} className="d-block w-100" /></div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-3"><img src={img5} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img6} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img7} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img8} className="d-block w-100" /></div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-3"><img src={img9} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img10} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img11} className="d-block w-100" /></div>
                                <div className="col-3"><img src={img12} className="d-block w-100" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>
    )
}

export default Workshop