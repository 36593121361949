import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/theater/oh-fish-office-oct-2023-cover.jpg'
import img1 from '../Assets/theater/lota-story.jpg'
import img2 from '../Assets/theater/Banner-4 -color Logo-Done.png'
import img3 from '../Assets//theater/Home Page-Banner (Landscape).jpg'

const Theater_list = () => {
  return (
    <div>
        <Header/>
        <div class="container-fluid">
        <div class="row mt-4">
            <div class="card col-3 gallery-card border-0">
                <img src={img} class="card-img-top" alt="... "/>
                <div class="card-body">
                    <h5 class="card-title">Oh-Fish Office October 2023</h5>
                    <a href="./oh-fish-office-oct-2023" class="btn btn-primary ">Full Album</a>
                </div>
            </div>
            <div class="card col-3 gallery-card border-0">
                <img src={img1} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h5 class="card-title">1943 A Lota Story</h5>
                    <a href="./theater_1943_a_lota_story" class=" btn btn-primary ">Full Album</a>
                </div>
            </div>
            <div class="card col-3 gallery-card border-0">
                <img src={img2} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h5 class="card-title">EK Bakwaas Love Story</h5>
                    <a href="./theater_ek_bakwaas_love_story" class=" btn btn-primary ">Full Album</a>
                </div>
            </div>
            <div class="card col-3 gallery-card border-0">
                <img src={img3} class="card-img-top" alt="... "/>
                <div class="card-body">
                    <h5 class="card-title">Oh-Fish Office</h5>
                    <a href="./theater_ohfish_office" class="btn btn-primary ">Full Album</a>
                </div>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Theater_list