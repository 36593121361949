import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/events/Story Teller Banner-Landscape-2.jpg'
import img1 from '../Assets/events/Banner -Mobile View.jpg'

const Storytelling = () => {
    return (
        <div>
            <Header />
            <div className="bg-light">
                <form
                //  action="/register" method="POST"
                >
                    <div className="container-fluid bg-light">
                        <div className="main-banner w-100">
                            <img src={img} alt="main banner" className="img-fluid w-100 d-none d-md-block" style={{ objectFit: 'cover', height: "70vh" }} />
                            <img src={img1} alt="main banner" className="img-fluid w-100 d-block d-md-none" />
                        </div>
                        <div className="bg-light p-3">
                            <div className="container">
                                <div className="section2 mt-2">
                                    <p className="fs-2 fw-bold">All India Story Telling Competition</p>
                                    <hr />
                                    <p align="justify">SARTRAC GLOBAL is pleased to announce <span className="fw-bold">“All India Story Telling Competition”</span> for children of age group 9-14yrs. Kids will be trained for storytelling competition under the guidance of famous Storyteller <strong className=" fw-bold"> <a className='a-hrok' href="#herokPal">Herok Pal.</a></strong></p>
                                    <ul className="event-points mt-2">
                                        <div className="row">
                                            <div className="col-6">
                                                <li>
                                                    <p>Boost their confidence</p>
                                                </li>
                                                <li>
                                                    <p>Improve their communication skills</p>
                                                </li>
                                                <li>
                                                    <p>Make learning easier</p>
                                                </li>

                                            </div>
                                            <div className="col-6">
                                                <li>
                                                    <p>Improve their social skills</p>
                                                </li>
                                                <li>
                                                    <p>Engage their creative side</p>
                                                </li>
                                                <li>
                                                    <p>Increase their cultural understanding</p>
                                                </li>

                                            </div>
                                        </div>
                                    </ul>
                                    <p className="para-background-2">Contest date: Saturday, 02<sup>nd</sup> July 2022 (Online contest). </p>

                                    <p><span className="fw-bold">Age Group:</span> 9 - 14 years.</p>
                                    <p><span className="fw-bold">Fees:</span> ₹4500 per child (Early bird discount ₹3000 till 13<sup>th</sup> June)</p>
                                    <h6 className="para-background">Batch starts from 15<sup>th</sup> June 2022. Total 8 sessions, 1.5 hours each (Classes every alternate days).</h6>
                                    <h5 className="mt-3">For any queries, contact us at:</h5>
                                    <p><span className="fw-bold">Email:</span> <a style={{ textDecoration: 'none', color: "black" }} href="/cdn-cgi/l/email-protection#a585d3ccc7cdc4e5d6c4d7d1d7c4c6c2c9cac7c4c98bc6cac8"><span className="__cf_email__" data-cfemail="a7d1cec5cfc6e7d4c6d5d3d5c6c4c0cbc8c5c6cb89c4c8ca">[email&#160;protected]</span></a>
                                    </p>
                                    <p><span className="fw-bold">Mobile:</span> <a style={{ textDecoration: 'none', color: "black" }} href="tel: +919891005929 ">+91 989 100 5929 </a>
                                    </p>
                                    <button className="btn bounce submit-btn mt-3 shadow p-2" formaction="/register">Register Yourself</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="bg-light p-3" id="herokPal">
                        <div className="container">
                            <p className="fs-2 fw-medium">About Herok Pal</p>
                            <hr />
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <iframe className="rounded shadow" width="530" height="250" src="https://www.youtube.com/embed/LuKYk09Tj2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <p align='justify' className="mt-2">Herok Pal is a certified Storyteller and has been pursuing his passion since 2018. During this journey, he has been fortunate to work and collaborate with some NGOs (Chris of Happiness, Hers is Ours), schools (Grads International School, Manav Rachna School, Gurgaon) and college (IIIT, Govindpuri) where he performed and created stories with likeminded people.</p>
                                    <p align='justify' className="mt-3">Herok has also performed at Tedx Parichowk and currently working as a Youtuber where shares his stories to all the age groups.</p>
                                    <p align='justify' className="mt-3"><a className="fw-bold" href="https://www.youtube.com/channel/UCLXEG13U37pem0cwXy6lz9w" target='_blank'>Click here</a> to watch some of his work.
                                    </p>


                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <Footer />
        </div>
    )
}

export default Storytelling