import React from 'react';
import Header from './Header';
import Footer from './Footer';
import img1 from '../Assets/sartracglobal.png'
import img2 from '../Assets/meditation.jpg'

const SartracGlobal = () => {
    return (
        <div>
            <Header />
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <img className="img-fluid header-image" src={img1} />
                        </div>
                    </div>
                </div>
                <div className="gap-1"></div>
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h1 className="display-6">WHO WE ARE</h1>
                        </div>
                    </div>
                    <div className="gap-1"></div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <p align="justify">SARTRAC Global aims at promoting and facilitating the concept of “Wholistic” (complete) wellness through the whole person approach – considering all the four aspects of our being ie. Body, mind, heart and Soul.</p>
                            <p align="justify">As it has been an established fact that an empowered individual is a happy individual, and empowerment comes from within – real empowerment is an inside job not an outside one. If an individual is happy from inside, he is a happy, healthy and prosperous part of the family, of the society, of the community and of course the whole humanity.</p>
                            <p align="justify">In order to help individuals to reach a place where not only they themselves feel empowered, blessed and inspired but also spread prosperity, empowerment and bliss all around them – we need to cater all part of an individual i.e. body (Physical wellness), mind (Mental wellness), heart (Emotional wellness) and soul (spiritual wellness). And it is also an established truth that if any part of the individual is left unattended or ignored or avoided than reaching at the ultimate destination of bliss, happiness and true prosperity will become a very unfulfilling journey.</p>
                            <p align="justify">At SARTRAC Global’s core is the whole person approach to help individuals to attain the level of true prosperity, happiness and bliss – call it “WHOLISTIC WELLNESS” – which includes physical, emotional, mental, financial, social and spiritual wellness. At SARTRAC Global we strive to help individual create the balance of material, emotional and spiritual aspect of their amazing life. At SARTRAC Global we strive to help individuals achieving their financial independence, optimum health, thriving relationship & self-actualization goals.</p>
                            <p align="justify">As already stated above – our mission is to facilitate, guide and empower individuals, families and groups to live a compassionate, happy and truly successful life inside-out. We, the team of SARTRAC Global with the help of workshops, seminars, camps, online and personal interactions, establishments like our WHOLISTIC WELLNESS CENTERS and tie ups with like minded individuals, groups and corporate entities – is determined to provide the much needed support, motivation and inspiration for the material, financial, emotional, physical and spiritual wellbeing of the individuals.</p>
                            <p align="justify">Be a part of the Micro Revolution – and join hands in creating a compassionate, empowered and prosperous community and do our bit to make this world a better place to live.</p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 text-center">
                            <img className="img-fluid vert-move" src={img2} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SartracGlobal