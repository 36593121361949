import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import SartracGlobal from "./components/SartracGlobal";
import Effectiveness from "./components/Effectiveness";
import ArunPrabhu from './components/ArunPrabhu'
import NaziaParwin from "./components/NaziaParwin";
import MaheshSharma from "./components/MaheshSharma";
import VibhaNasheen from "./components/VibhaNasheen";
import HerokPal from "./components/HerokPal";
import MemberShip from "./components/MemberShip";
import StoryWorkshop from "./components/StoryWorkshop";
import Storytelling from "./components/Storytelling";
import Register from "./components/Register";
import Ohfish from "./components/Ohfish";
import Independance from "./components/Independance";
import News from './components/News'
import Gallery from './components/Gallery'
import Registration from "./components/Registration";
import Theater_Ek_Bakwaas_Love_Story from './components/Theater_Ek_Bakwaas_Love_Story'
import Theater_list from "./components/Theater_list";
import Theater_Ohfish_Office from './components/Theater_Ohfish_Office'
import A_Lota_Story from "./components/A_Lota_Story";
import Oh_Fish_Office_October_2023 from "./components/Oh_Fish_Office_October_2023";
import Virasat from "./components/Virasat";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='sartrac' element={<SartracGlobal/>}/>
          <Route path="/effectiveness" element={<Effectiveness/>}/>
          <Route path='/arunprabhu' element={<ArunPrabhu/>}/>
          <Route path='/naziaparwin' element={<NaziaParwin/>}/>
          <Route path='/maheshsharma' element={<MaheshSharma/>}/>
          <Route path='/vibhanasheen' element={<VibhaNasheen/>}/>
          <Route path='/herokpal' element={<HerokPal/>}/>
          <Route path='/membership' element={<MemberShip/>}/>
          <Route path='/story-workshop' element={<StoryWorkshop/>}/>
          <Route path='/story_telling' element={<Storytelling/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path='/theater' element={<Ohfish/>}/>
          <Route path='/independance-day-2022' element={<Independance/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/gallery' element={<Gallery/>}/>
          <Route path='/registration' element={<Registration/>}/>
          <Route path="/theater_ek_bakwaas_love_story" element={<Theater_Ek_Bakwaas_Love_Story/>}/>
          <Route path="/theater_list" element={<Theater_list/>}/>
          <Route path="/theater_ohfish_office" element={<Theater_Ohfish_Office/>}/>
          <Route path="/theater_1943_a_lota_story" element={<A_Lota_Story/>}/>
          <Route path="/oh-fish-office-oct-2023" element={<Oh_Fish_Office_October_2023/>}/>
          <Route path='/virasat-2023' element={<Virasat/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
