import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Header from './Header';
import video from '../Assets/candle.mp4';

const Registration = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobilenumber: '',
    password: '',
    confirmpassword: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send('service_j1gqme9', 'template_bl7tmlm', formData, 'wfXLLuPLJfP7b5ROM')
      .then((result) => {
          console.log(result.text);
          alert('Registration successful! Email sent.');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send email. Please try again.');
      });

    setFormData({
      firstname: '',
      lastname: '',
      email: '',
      mobilenumber: '',
      password: '',
      confirmpassword: '',
    });
  };

  return (
    <div>
      <Header />
      <div id="video-back">
        <video playsInline loop muted autoPlay>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="content d-flex">
          <div className="container-fluid sartrac-form-container">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6"></div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <form onSubmit={sendEmail}>
                  <div className="container-fluid align-items-center">
                    <h1 className="display-6 text-center sartrac-header">Register Here</h1>
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            <i className="far fa-id-card"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name*"
                            name="firstname"
                            value={formData.firstname}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            <i className="far fa-id-card"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name*"
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            <i className="fas fa-envelope-open-text"></i>
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email ID*"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            <i className="fas fa-mobile-alt"></i>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Mobile Number*"
                            name="mobilenumber"
                            value={formData.mobilenumber}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <button type="submit" className="btn btn-block btn-primary">REGISTER</button>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <button type="reset" className="btn btn-block btn-warning">RESET</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;

