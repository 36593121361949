import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/membership.png'
import img1 from '../Assets/silver.png'
import img2 from '../Assets/gold.png'
import img3 from '../Assets/diamond.png'
import img4 from '../Assets/finance.png'
import img5 from '../Assets/relation.png'
import img6 from '../Assets/personality.png'
import img7 from '../Assets/creative.png'
import img8 from '../Assets/social.png'
import img9 from '../Assets/spiritual.png'
import img10 from '../Assets/women-centric.png'
import img11 from '../Assets/health.png'
import img12 from '../Assets/mentor.png'

const MemberShip = () => {
  return (
    <div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <img className="img-fluid header-image" src={img} />
                </div>
            </div>
        </div>
        <div className="gap-1"></div>
        <div className="container-fluid">
            <div className="row text-center">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <h1 className="display-6">SARTRAC FAMILY CARDS</h1>
                </div>
            </div>
        </div>
        <div className="gap-1"></div>
        <div className="container-fluid">
            <div className="row text-center">
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <img src={img} className="img-fluid"/>
                    <div className="gap-1"></div>
                    <p><a class = "btn btn-block btn-warning" href="registration.php">Register for <strong>FAMILY CARD</strong></a></p>
                    <p><strong> ELIGIBILITY AND BENEFITS </strong></p>
                    <p>
                        <ul className="card-details">
                            <li>Card holder store your health and other information there.</li>
                            <li>Card holder attend free counselling sessions/free workshops conducted by mentors.</li>
                            <li>Card holder will be shared our free workshop details and you can attend workshops of your choice.</li>
                            <li>Card holder will be eligible for discounts on workshops.counselling sessions conducted by mentors.</li>
                            <li>Card holder avail discounts for all health products which Sartrac promotes or distributes.</li>
                        </ul>
                    </p>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <img src={img1} className="img-fluid"/>
                    <div className="gap-1"></div>
                    <p><a class = "btn btn-block btn-warning" href="silverregistration.php">Register for <strong>SILVER CARD</strong></a></p>
                    <p><strong> ELIGIBILITY AND BENEFITS </strong></p>
                    <p>
                        <ul className="card-details">
                            <li><strong>All benefits included in the Family Card.</strong></li>
                            <li>For all those who have attended at least one paid event of Sartrac Global they will get Silver card.</li>
                            <li>More discounts.</li>
                            <li>More value for money.</li>
                            <li>Free of cost and valid for 5 years.</li>
                        </ul>
                    </p>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <img src={img2} className="img-fluid"/>
                    <div className="gap-1"></div>
                    <p><a class = "btn btn-block btn-warning" href="goldregistration.php">Register for <strong>GOLD CARD</strong></a></p>
                    <p><strong> ELIGIBILITY AND BENEFITS </strong></p>
                    <p>
                        <ul className="card-details">
                            <li><strong>All benefits included in the Silver Card.</strong></li>
                            <li>This is a paid card which gives you free access for 6 months to all the workshops, yoga sessions, counseling, diet consultation, mentoring , events , discount coupons for health products etc for 6 months and is renewable.</li>
                        </ul>
                    </p>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <img src={img3} className="img-fluid"/>
                    <div className="gap-1"></div>
                    <p><a class = "btn btn-block btn-warning" href="diamondregistration.php">Register for <strong>DIAMOND CARD</strong></a></p>
                    <p><strong> ELIGIBILITY AND BENEFITS </strong></p>
                    <p>
                        <ul className="card-details">
                            <li><strong>This card is for the mentors.</strong></li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
        <div className="gap-1"></div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h1 className="display-6">OUR PROGRAMS</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 text-center">
                    <img src={img4} className="img-fluid"/><br/>
                    <p><strong>FINANCIAL</strong></p>
                </div>
                <div className="col-sm-4 text-center">
                    <img src={img5} className="img-fluid"/><br/>
                    <p><strong>RELATIONSHIPS</strong></p>
                </div>
                <div className="col-sm-4 text-center">
                    <img src={img6} className="img-fluid"/><br/>
                    <p><strong>PERSONALITY DEVELOPMENT</strong></p>
                </div>
            </div>
            <div className="gap-1"></div>
            <div className="row">
                <div className="col-sm-4 text-center">
                    <img src={img7} className="img-fluid"/><br/>
                    <p><strong>CREATIVE</strong></p>
                </div>
                <div className="col-sm-4 text-center">
                    <img src={img8} className="img-fluid"/><br/>
                    <p><strong>SOCIAL</strong></p>
                </div>
                <div className="col-sm-4 text-center">
                    <img src={img9} className="img-fluid"/><br/>
                    <p><strong>SPIRITUAL</strong></p>
                </div>
            </div>
            <div className="gap-1"></div>
            <div className="row">
                <div className="col-sm-4 text-center">
                    <img src={img10} className="img-fluid"/><br/>
                    <p><strong>WOMEN CENTRIC</strong></p>
                </div>
                <div className="col-sm-4 text-center">
                    <img src={img11} className="img-fluid"/><br/>
                    <p><strong>HEALTH AND WELLNESS</strong></p>
                </div>
                <div className="col-sm-4 text-center">
                    <img src={img12} className="img-fluid"/><br/>
                    <p><strong>PERSONALISED COACHING</strong></p>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default MemberShip