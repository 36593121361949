import React from 'react'
import img1 from '../Assets/member.png'
import img2 from '../Assets/silver.png'
import img3 from '../Assets/gold.png'
import img4 from '../Assets/diamond.png'

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="gap-1"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-3 text-center">
              <p>FACEBOOK UPDATES</p>
              <div id="fb-root"></div>
              <div className="fb-page" data-href="https://www.facebook.com/sartracglobal" data-tabs="timeline" data-width="300px" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/sartracglobal" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/sartracglobal">Sartrac Global</a></blockquote></div>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3 text-center">
              <p>RECENT TWEETS</p>
              <a className="twitter-timeline" data-height="500" href="https://twitter.com/SartracG?ref_src=twsrc%5Etfw">Tweets by SartracG</a>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              <p align="center">SARTRAC MEMBERSHIP CARDS</p>
              <p><img src={img1} className="img-fluid" /></p>
              <p align="center"><strong>FAMILY CARD</strong></p>
              <div className="gap-1"></div>
              <p><img src={img2} className="img-fluid" /></p>
              <p align="center"><strong>SILVER CARD</strong></p>
              <div className="gap-1"></div>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              <p align="center">SARTRAC MEMBERSHIP CARDS</p>
              <p><img src={img3} className="img-fluid" /></p>
              <p align="center"><strong>GOLD CARD</strong></p>
              <div className="gap-1"></div>
              <p><img src={img4} className="img-fluid" /></p>
              <p align="center"><strong>DIAMOND CARD</strong></p>
              <div className="gap-1"></div>
            </div>
          </div>
        </div>
        <div className="gap-1"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              &copy 2020. All Rights Reserved with Sartrac Global
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer