import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Workshop from './Workshop'
import HeaderBanner from './HeaderBanner'

const Home = () => {
  return (
    <div>
        <Header/>
        <HeaderBanner/>
        <Workshop/>
        <Footer/>
    </div>
  )
}

export default Home