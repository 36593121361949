import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/theater/theater-banner.jpg'
import img1 from '../Assets/theater/theater-banner-mobile.jpg'
import img2 from '../Assets/events/banner-mobile.jpg'

const Ohfish = () => {
  return (
    <div>
        <Header/>
        <form 
        // action="register" method="POST"
        >
        <div className="container-fluid bg-light">
            <div className="main-banner w-100">
                <img src={img} alt="main banner" className="img-fluid pb-3 w-100 d-none d-md-block"/>
                <img src={img1} alt="main banner" className="img-fluid pb-3 w-100 d-block d-md-none"/>
            </div>
            <div className="bg-white">
                <div className="section2 mt-5">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 text-center">
                            <img src={img2} alt="" className="img-fluid rounded shadow" style={{height: "400px"}}/>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <h3 className="text-center mb-3">OH-FISH OFFICE</h3>
                            <p align="justify"><span className="fw-bold">OH-FISH OFFICE</span> is a comical satire on today`s modern Corporate Office environment. It is a collage of professionals as characters, in a cutthroat competition, however, carry human values behind their actions and choices. The contrast of shades brought by this colorful team; comprising of new hires, young romantic birds, vintage poetry lovers, and a `Jasoos` peon; makes the office story colorful yet relatable for the audience.</p>
                            <div className="row">
                                <div className="col-6">
                                    <p><span className="fw-bold">Date:</span> July, 31 2022</p>
                                </div>
                                <div className="col-6">
                                    <p><span className="fw-bold">Time:</span> 06:30pm - 08:30pm</p>
                                </div>
                            </div>
                            <p><span className="fw-bold">Price:</span>₹149/-, ₹299/-</p>
                            <p><span className="fw-bold">Address:</span> The Little Theatre Group Auditorium, 1, Copernicus
                                Marg, Mandi House, Opp. Doordarshan Bhavan, near Connaught Place, New Delhi, Delhi, 110001
                            </p>
                            <h5 className="text-danger">For any query related to Booking, Contact at:</h5>
                            <p><span className="fw-bold">Email:</span> <a style={{textDecoration: "none", color:"black"}} href="/cdn-cgi/l/email-protection#fada9b888f94ba9b919b949189929b9b889b9e92839b9c958f949e9b8e939594d4999597"><span className="__cf_email__" data-cfemail="422330372c022329232c29312a23233023262a3b23242d372c2623362b2d2c6c212d2f">[email&#160;protected]</span></a>
                            </p>
                            <p><span className="fw-bold">Mobile:</span> <a style={{textDecoration: "none", color:"black"}} href="tel: +91 88822 12649">+91 88822 12649</a>
                            </p>
                            <a href="https://in.bookmyshow.com/plays/oh-fish-office/ET00332565" target="_blank" type="button" name="submit" className="btn w-100 btn-success p-2">Book Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 mb-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.217645287428!2d77.22912731462122!3d28.623238391253313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd2cd353fd57%3A0x27462f5715d48c3e!2sLittle%20Theatre%20Group%20(LTG)%20Auditorium!5e0!3m2!1sen!2sin!4v1647080920575!5m2!1sen!2sin" style={{border:'0', width: '100%', height: "400px" }}allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
    </form>
        <Footer/>
    </div>
  )
}

export default Ohfish