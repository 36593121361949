import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/arunprabhu.png'
import img1 from '../Assets/arun.png'

const ArunPrabhu = () => {
  return (
    <div>
        <Header/>
        <div className="arun-background">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <img className="img-fluid header-image" src={img} />
                </div>
            </div>
        </div>
        <div className="gap-1"></div>
		<div className="container-fluid">
			<div className="row text-center">
				<div className="col-sm-12 col-md-12 col-lg-12">
					<h1 className="display-6">ARUN PRABHU</h1>
				</div>
			</div>
			<div className="gap-1"></div>
			<div className="row">
				<div className="col-sm-6 col-md-6 col-lg-6">
					<p align="justify">A mechanical engineer by education, an IT person by profession and an author, coach, speaker and movie maker by passion. He started his career in an upcoming IT industry and soon started on his own. He reached success at a very early stage but in next few years he saw lot of ups and downs in his career and these years gave him an insight of why even after good education people fail in their life. This motivated him to write his experiences in the form of book so that people can gain knowledge from his experiences. His first book “Count Your Chicken Before You Lay Them” has gained great acceptance and excellent reviews. He has also conducted guest lectures in colleges and is associated with various NGOs and organizations like Speak to Change Foundation where they empower people by teaching them how to express freely and ATGC an NGO where they adopt girl child as their own daughters. </p>
					<p align="justify">“Count Your Chicken Before You Lay Them” is about how to live life with respect to modern ecosystem where the principles are not sacrificed and at the same time a balanced fulfilling life is lived. It is written in a story format with a sweet love story to keep readers engaged along with the message.</p>
				</div>
				<div className="col-sm-6 col-md-6 col-lg-6 text-center">
					<img className="img-fluid vert-move" src={img1}/>
				</div>
			</div>
		</div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <p align="justify">His second book “Niharika.. I want to live again” is also a fiction based novel delving in matters relating to marriages. It is a story of a single mom and her daughter and her struggles. The author has tried to showcase the readers the mistakes we do as couples and how in simple ways the bond can be cultivated into an everlasting bond. His third book “Create Your Own Universe” is a step to step guide how to create a blissful life full of abundance for ourselves.His ambition is to write more and more books on social issues and solutions for that. All his books are based on fiction and most of them are in first person so that the readers can relate to it. He is also making movies out of his books so that the message can be spread far and wide.Apart from this he conducts workshops, seminars and talk shows to convey his message of self-empowerment with unconditional love. A great propagator of Law of Attraction and Universe conspires theory his workshops enable the attendees to get a scientific approach towards laws of nature and universe. His interviews can be found on Google, YouTube and other websites as well as All India Radio.His organization Sartrac Global aims at creating cure centers around the globe where curing is the main aim over patient treatment. Sartrac also plans to setup Gurukul education system where knowledge prevails over career development. Where overall development of human beings is the core focus.</p>
                </div>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
  )
}

export default ArunPrabhu