import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/theater/theater.webp'
import img1 from '../Assets/virasat-2023/virasat-2023-header.jpg'

const Gallery = () => {
  return (
    <div>
        <Header/>
        <div class="container-fluid">
        <div class="row mt-4">
            <div class="card col-3 gallery-card border-0">
                <img src={img} class="card-img-top" alt="... "/>
                <div class="card-body">
                    <h5 class="card-title">Theater</h5>
                    <a href="./theater_list " class="btn btn-primary ">Full Album</a>
                </div>
            </div>
            <div class="card col-3 gallery-card border-0">
                <img src={img1} class="card-img-top" alt="... "/>
                <div class="card-body">
                    <h5 class="card-title">Virasat-2023</h5>
                    <a href="./virasat-2023 " class="btn btn-primary ">Full Album</a>
                </div>
            </div>
        </div>
        </div>
   
        <Footer/>
    </div>
  )
}

export default Gallery