import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import a from "../Assets/theater/1943-a-lota-story/IMG_2025.JPG";
import b from "../Assets/theater/1943-a-lota-story/IMG_2030.JPG";
import c from "../Assets/theater/1943-a-lota-story/IMG_2031.JPG";
import d from "../Assets/theater/1943-a-lota-story/IMG_2051.JPG";
import e from "../Assets/theater/1943-a-lota-story/IMG_2065.JPG";
import f from "../Assets/theater/1943-a-lota-story/IMG_2071.JPG";
import g from "../Assets/theater/1943-a-lota-story/IMG_2073.JPG";
import h from "../Assets/theater/1943-a-lota-story/IMG_2082.JPG";
import i from "../Assets/theater/1943-a-lota-story/IMG_2088.JPG";
import j from "../Assets/theater/1943-a-lota-story/IMG_2097.JPG";
import k from "../Assets/theater/1943-a-lota-story/IMG_2099.JPG";
import l from "../Assets/theater/1943-a-lota-story/IMG_2110.JPG";
import m from "../Assets/theater/1943-a-lota-story/IMG_2116.JPG";
import n from "../Assets/theater/1943-a-lota-story/IMG_2121.JPG";
import o from "../Assets/theater/1943-a-lota-story/IMG_2165.JPG";
import p from "../Assets/theater/1943-a-lota-story/IMG_2168.JPG";
import q from "../Assets/theater/1943-a-lota-story/IMG_2172.JPG";

import r from "../Assets/theater/1943-a-lota-story/IMG_2183.JPG";
import s from "../Assets/theater/1943-a-lota-story/IMG_2199.JPG";
import t from "../Assets/theater/1943-a-lota-story/IMG_2202.JPG";
import u from "../Assets/theater/1943-a-lota-story/IMG_2206.JPG";


const A_Lota_Story = () => {
  const images = [
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
    m,
    n,
    o,
    p,
    q,
    r,
    s,
    t,
    u,
  ];
  return (
    <div>
      <Header />
      <h1 style={{textAlign:'center',margin:'10px auto'}}>
        <u>1943 A Lota Story</u>
      </h1>

      <div className="container-fluid">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="item col-sm-6 col-md-3 mb-3" key={index}>
              <a href={image} className="fancybox" data-fancybox="gallery1">
                <img
                  src={image}
                  width="100%"
                  className="img-fluid height_set"
                  alt={`Slide ${index + 1}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default A_Lota_Story;
