import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img1 from '../Assets/Sartrac-Landscape -Banner.jpg'
import img2 from '../Assets/Sartrac-Portrait -Banner.jpg'
import img3 from '../Assets/Financial Intelligence -1.png'
import img4 from '../Assets/Cash Flow Quadrant -2.png'
import img5 from '../Assets/Pre-Post Marriage.png'
import img6 from '../Assets/Parenting Journey.png'
import img7 from '../Assets/Exclusive for Teens.png'
import img8 from '../Assets/Team Building.png'
import img9 from '../Assets/OBJECTIVE OF THE WORKSHOP.png'
import img10 from '../Assets/LEADERSHIP.png'
import img11 from '../Assets/Lead To Grow.png'
import img12 from '../Assets/PRODUCTIVITY.png'
import img13 from '../Assets/Win Big with People.png'
import img14 from '../Assets/Attracting Sales.png'
import img15 from '../Assets/Health is Wealth.png'
import img16 from '../Assets/Speak your heart out.png'
import img17 from '../Assets/Spiritual side of corporate.png'
import img18 from '../Assets/Corporate Yoga.png'
import img19 from '../Assets/Story telling ( Corporate way).jpg'
import img20 from '../Assets/Singing Classes.png'
import img21 from '../Assets/Music Classes.png'
import img22 from '../Assets/Dance Classes.png'
import img23 from '../Assets/Acting Classes.png'
import img24 from '../Assets/Art Director.png'
import img25 from '../Assets/Photography.png'
import img26 from '../Assets/Graphic Design.png'
import img27 from '../Assets/Digital Marketing.png'
import img28 from '../Assets/Drawing panting - sketching.png'
import img29 from '../Assets/spiritual.png'
import img30 from '../Assets/poornima.jpg'
import img31 from '../Assets/Women at Workplace Act.png'
import img32 from '../Assets/personal_coaching.png'

const Effectiveness = () => {
  return (
    <div>
        <Header/>
        <button type="button" className="btn btn-danger btn-floating btn-lg" id="btn-back-to-top">
            <i className="fas fa-arrow-up"></i>
        </button>
    
    <div className="d-none d-md-block" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="img-fluid d-block w-100" src={img1}
                    alt="..."/>
                <span className="carousel-caption text-dark"
                    style={{backgroundColor: 'rgb(227 227 227 / 32%)', right: '28%', left: '34%', bottom: '-0.75rem'}}>
                    <h1><strong>FROM EFFECTIVENESS</strong></h1>
                    <h1 style={{color: "red"}}><strong>TO GREATNESS</strong></h1>
                </span>
            </div>
        </div>
    </div>

    <div className="d-md-none d-block" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="img-fluid" src={img2} alt=""/>
                <div className="carousel-caption text-dark" style={{textAlign:'left', left: '5px !important'}}>
                    <h1><strong>FROM EFFECTIVENESS</strong></h1>
                    <h1 style={{color: "red"}}><strong>TO GREATNESS</strong></h1>
                </div>
            </div>
        </div>
    </div>
    <div className="gap-1"></div>

    <div className="container">
       
        <ul>
            <li className="content"><a href="#financial" className="text-dark">
                    <h3>Financial</h3>
                </a></li>
            <li className="content"><a href="#relationships" className="text-dark">
                    <h3>Relationships</h3>
                </a></li>
            <li className="content"><a href="#corporate" className="text-dark">
                    <h3>Corporate</h3>
                </a></li>
            <li className="content"><a href="#creative" className="text-dark">
                    <h3>Creative</h3>
                </a></li>
            <li className="content"><a href="#" className="text-dark">
                    <h3>Social</h3>
                </a></li>
            <li className="content"><a href="#spiritual" className="text-dark">
                    <h3>Spiritual</h3>
                </a></li>
            <li className="content"><a href="#womenCentric" className="text-dark">
                    <h3>Women Centric</h3>
                </a></li>
            <li className="content"><a href="#" className="text-dark">
                    <h3>Health & Wellness</h3>
                </a></li>
            <li className="content"><a href="#personalCoaching" className="text-dark">
                    <h3>Personal Coaching</h3>
                </a></li>
        </ul>
    </div>

    <div className="gap-1"></div>

    <div className="container bg-light">
        <h1 id="financial" className="Display-2"><strong>Financial</strong></h1>
        <hr/>
        <ol>
            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Financial Intelligence</li>
                        </h2>

                        <h2 className="contectPara">Money is a Resource</h2>
                        <ul>
                            <h4>
                                <li>Managing your finances</li>
                            </h4>
                            <h4>
                                <li>How to get a debt</li>
                            </h4>
                            <h4>
                                <li>Cost benefit analysis</li>
                            </h4>
                            <h4>
                                <li>Law of compounding</li>
                            </h4>
                            <h4>
                                <li>Building Assets</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img3} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Cash Flow Quadrant</li>
                        </h2>

                        <h2 className="contectPara">Cashflow is like river</h2>
                        <ul>
                            <h4>
                                <li>What is cashflow</li>
                            </h4>
                            <h4>
                                <li>Concept of ESBI</li>
                            </h4>
                            <h4>
                                <li>Assets v/s Liabilities</li>
                            </h4>
                            <h4>
                                <li>Building Cashflow</li>
                            </h4>
                            <h4>
                                <li>Financial Freedom</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img4} alt=""/>

                    </div>
                </div>
            </div>
        </ol>
        <div className="gap-1"></div>
    </div>
    <div className="gap-1"></div>

    <div className="container bg-light">
        <h1 id="relationships" className="Display-2"><strong>Relationships</strong></h1>
        <hr/>

        <ol>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Pre/Post Marriage</li>
                        </h2>

                        <h2 className="contectPara">“Marriage are made in heaven
                            but lived on earth”
                        </h2>
                        <ul>
                            <h4>
                                <li>The perfect marriage</li>
                            </h4>
                            <h4>
                                <li>Understanding temperamental Differentiation</li>
                            </h4>
                            <h4>
                                <li>Compromise V/S Acceptance</li>
                            </h4>
                            <h4>
                                <li>Everyone has Unique love language</li>
                            </h4>
                            <h4>
                                <li>Finding common goal</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img5} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Parenting Journey</li>
                        </h2>

                        <h2 className="contectPara">“Developing Deeper Bonds with our kids”</h2>
                        <ul>
                            <h4>
                                <li>Understand self first</li>
                            </h4>
                            <h4>
                                <li>Developing quality communication with kids</li>
                            </h4>
                            <h4>
                                <li>Nurturing bonds through empathy</li>
                            </h4>
                            <h4>
                                <li>Keeping ourselves updated and relevant</li>
                            </h4>
                            <h4>
                                <li>Devoting quality time & quality attention</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img6} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Exclusive for Teens</li>
                        </h2>

                        <h2 className="contectPara">“Developing & Nurturing the Identity”</h2>
                        <ul>
                            <h4>
                                <li>Who we are and How we get here</li>
                            </h4>
                            <h4>
                                <li>Understanding & Addressing self-identity</li>
                            </h4>
                            <h4>
                                <li>Discovering passion & Developing courage</li>
                            </h4>
                            <h4>
                                <li>Identify the subconscious blocks</li>
                            </h4>
                            <h4>
                                <li>Developing productive, effective & significant habits</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img7} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Team Building</li>
                        </h2>

                        <h2 className="contectPara">“Together we swim or together we sink”</h2>
                        <ul>
                            <h4>
                                <li>Identifying your strengths and weaknesses</li>
                            </h4>
                            <h4>
                                <li>Identifying others strengths and weaknesses</li>
                            </h4>
                            <h4>
                                <li>Working to your strengths and diluting your weaknesses</li>
                            </h4>
                            <h4>
                                <li>Going along with different temperaments</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img8} alt=""/>

                    </div>
                </div>
            </div>
        </ol>
        <div className="gap-1"></div>
    </div>
    <div className="container bg-light">
        <h1 id="corporate" className="Display-2"><strong>Corporate</strong></h1>
        <hr/>

        <div className="container">
            <div className="row">
                <h2 className="text-center">OBJECTIVE OF THE WORKSHOP</h2>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <img className="img-fluid content_img" src={img9} alt=""/>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h5>
                        <p align="justify">The workshop is all about
                            knowing and understanding different personality patterns, recognizing and work on the actual
                            strength and weaknesses of different personalities, how to smoothly get along with others
                            and be more effective and productive, how to establish effective communication and build
                            rapport with clients, customers and anyone else who really matters. This workshop not only
                            provides the participants the understanding and knowledge of their basic personality
                            patterns but also equip them to observationally identify those patterns in others and build
                            great relationships, close more sales, earn more trust, create great teams and, last but not
                            the least become a 'master people person'.</p>
                    </h5>
                </div>
            </div>
        </div>
        <div className="gap-1"></div>

        <ol>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Leadership</li>
                        </h2>

                        <h2 className="contectPara">“Developing & Nurturing the Identity”</h2>

                        <h3>Program for Senior management (Duration: 1 and 2 days)</h3>
                        <ul>
                            <h4>
                                <li>Effective leadership which inspires trust and confidence.</li>
                            </h4>
                            <h4>
                                <li>Effective change management and its implications.</li>
                            </h4>
                            <h4>
                                <li>Making sure the harmony and balance is not affected.</li>
                            </h4>
                            <h4>
                                <li>Effective conflict resolution.</li>
                            </h4>
                            <h4>
                                <li>Inspired leadership among the following leaders.</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img10} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Leadership</li>
                        </h2>

                        <h2 className="contectPara">“Lead To Grow”</h2>

                        <h3>Program for budding leaders (Duration: 1 and 2 days)</h3>

                        <ul>
                            <h4>
                                <li>The rights and responsibility of a leaderThe rights and responsibility of a leader
                                </li>
                            </h4>
                            <h4>
                                <li>How to be a people person</li>
                            </h4>
                            <h4>
                                <li>Why 'talk the walk' is important</li>
                            </h4>
                            <h4>
                                <li>The important role of perspective</li>
                            </h4>
                            <h4>
                                <li>Defining assumptions, attitude, and priorities</li>
                            </h4>
                            <h4>
                                <li>Getting out of the box and promote clarity</li>
                            </h4>
                            <h4>
                                <li>Building a strong team</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img11} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Productivity</li>
                        </h2>

                        <h2 className="contectPara">Achieving Organizational Excellence</h2>

                        <h3>Through Personal Effectiveness mass orientation behavioural excellence program. (Duration:
                            1- & 2-days format)</h3>
                        <ul>
                            <h4>
                                <li>Knowing our own selves before knowing the world around us.</li>
                            </h4>
                            <h4>
                                <li>Enhancing the strengths and minimizing the weakness.</li>
                            </h4>
                            <h4>
                                <li>Getting along with others who are not like us.</li>
                            </h4>
                            <h4>
                                <li>Attitude sessions and putting priorities in perspective.</li>
                            </h4>
                            <h4>
                                <li>Creating and maintain the work-life balance</li>
                            </h4>
                            <h4>
                                <li>Goal setting and time management.</li>
                            </h4>
                            <h4>
                                <li>Making commitment to lifelong learning and taking.</li>
                            </h4>
                            <h4>
                                <li>Charge of personal actions and results</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img12} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Productivity</li>
                        </h2>

                        <h2 className="contectPara">Win Big with People</h2>

                        <h3>For Those Who Deal With People. (Duration : 1 day)</h3>
                        <ul>
                            <h4>
                                <li>Sales & Marketing Force</li>
                            </h4>
                            <h4>
                                <li>Insurance Advisors / Managers</li>
                            </h4>
                            <h4>
                                <li>HR Personnel & Counsellors</li>
                            </h4>
                            <h4>
                                <li>Upcoming Leaders</li>
                            </h4>
                            <h4>
                                <li>Front Line & Front Desk Employees</li>
                            </h4>
                            <h4>
                                <li>Network Marketing Professionals</li>
                            </h4>
                            <h4>
                                <li>People at Leadership Positions</li>
                            </h4>
                            <h4>
                                <li>Anyone who deals with people in any capacity</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img13} alt=""/>

                    </div>
                </div>
            </div>
            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Attracting Sales</li>
                        </h2>

                        <h2 className="contectPara">“You either make a sale or create a relation.”</h2>

                        <h3>The biggest challenge every corporate faces is to achieve sales.</h3>
                        <h3>Sales personals are always at stress as to how to set “realistic” targets and Achieve them.
                        </h3>
                        <h3>Sartrac offers a special focussed approach to achieve the following:</h3>
                        <ul>
                            <h4>
                                <li>Setting “realistic” Sales targets</li>
                            </h4>
                            <h4>
                                <li>Creating customer relationships</li>
                            </h4>
                            <h4>
                                <li>Team building and sales task force motivation</li>
                            </h4>
                            <h4>
                                <li>Closing a win sales deal</li>
                            </h4>
                            <h4/>
                                <li>Attracting sales vs chasing sales</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img14} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Health is Wealth</li>
                        </h2>

                        <h2 className="contectPara">“As per the economic me of june 2016 more than 46% of workforce in firms
                            in india suffer from some or the other form of stress.”</h2>

                        <h3>And this stress leads to health challenges, team friction and inefficiency red vs blue</h3>
                        <ul>
                            <h4>
                                <li>Idenfying stress</li>
                            </h4>
                            <h4>
                                <li>Idenfying Emotional blockages</li>
                            </h4>
                            <h4>
                                <li>Releasing blockages and negative emotions</li>
                            </h4>
                            <h4>
                                <li>Using passion and aggression as a positive force</li>
                            </h4>
                            <h4>
                                <li>Best health process</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img15} alt=""/>

                    </div>
                </div>
            </div>
            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Speak your heart out</li>
                        </h2>

                        <h2 className="contectPara">I did very well but do I present it to my boss, team or management?</h2>

                        <h3>One of the most common fear an employee faces I expressing himself or herself, whether it is
                            public speaking, making a presentation or giving demonstration.</h3>
                        <ul>
                            <h4>
                                <li>Talk it Out</li>
                            </h4>
                            <h4>
                                <li>Public speaking getting the fear out</li>
                            </h4>
                            <h4>
                                <li>Making impressive and expressive presentations</li>
                            </h4>
                            <h4>
                                <li>Be Apt yet expressive</li>
                            </h4>
                            <h4>
                                <li>Demonstrate to make them understand</li>
                            </h4>
                            <h4>
                                <li>Communicate and not just tell.</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img16} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Spiritual side of corporate</li>
                        </h2>

                        <h2 className="contectPara">“The law of least effort says how to benefit more by doing less. How to
                            swim with the waves?"</h2>

                        <h3>More and more corporates are now understanding the importance of acknowledging the spiritual
                            side of businesses.</h3>
                        <h3>When we understand that business is not the life but a part of life and like dimensions of
                            life commerce is also guided by some spiritual laws..</h3>
                        <h3>Ride with the waves 7 spiritual laws of success as told by Dr. DEEPAK CHOPRA:</h3>
                        <ul>
                            <h4>
                                <li>Lows of pure potentiality</li>
                            </h4>
                            <h4>
                                <li>Laws of giving and receiving</li>
                            </h4>
                            <h4>
                                <li>Law of karma</li>
                            </h4>
                            <h4>
                                <li>Law of least effort</li>
                            </h4>
                            <h4>
                                <li>Law of intention and desire</li>
                            </h4>
                            <h4>
                                <li>Law of Dharma</li>
                            </h4>
                            <h4>
                                <li>The significance of all the above laws in corporate world.</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img17} alt=""/>

                    </div>
                </div>
            </div>
            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Corporate Yoga</li>
                        </h2>

                        <h2 className="contectPara">Yoga is essentially a spiritual discipline based on an extremely subtle
                            science, which focuses on bringing harmony between mind and body.</h2>

                        <h3>As we known that human is very uncomfortable to sit at one place like office work.</h3>
                        <h3>For that Corporate yoga classes offers an easy, low cost and innovative solution for
                            company, its helps to reduce stress of work and build health.</h3>
                        <h3>Corporate yoga offers a contract to teach at your company or at SARTRAC GLOBAL, invite as to
                            your work space and see changes in yourself.</h3>
                        <div className="gap-1"></div>
                        <h3>These types of yoga:</h3>
                        <ul>
                            <h4>
                                <li>Chair Cat-Cow Stretch</li>
                            </h4>
                            <h4>
                                <li>Chair Raised Hands Pose</li>
                            </h4>
                            <h4>
                                <li>Chair Extended Side Angle</li>
                            </h4>
                            <h4>
                                <li>Chair Pigeon</li>
                            </h4>
                            <h4>
                                <li>Chair Spinal Twist</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img18} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>STORY TELLING THE CORPORATE WAY</li>
                        </h2>
                        <ul>
                            <h4>
                                <li>Selling the Idea through a story</li>
                            </h4>
                            <h4>
                                <li>How to Inspire through a story</li>
                            </h4>
                            <h4>
                                <li>Relax and entertain through a story</li>
                            </h4>
                            <h4>
                                <li>Stories Connect well Through self-experience</li>
                            </h4>
                            <h4>
                                <li>Audience relates well to stories</li>
                            </h4>
                            <h4>
                                <li>Message get delivered through story telling</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img19}
                            alt=""/>

                    </div>
                </div>
            </div>
        </ol>
        <div className="gap-1"></div>
    </div>
    <div className="container bg-light">
        <h1 id="creative" className="Display-2"><strong>Creative</strong></h1>
        <hr/>
        <div className="gap-1"></div>

        <ol>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Singing Classes</li>
                        </h2>

                        <h2 className="contectPara">How to express your inner feelings?</h2>

                        <h3>Our Singing Classes are carefully designed by our faculty to help aspiring singers achieve
                            everything they have always wanted to with their voice.</h3>
                        <ul>
                            <h4>
                                <li>Breathing Techniques</li>
                            </h4>
                            <h4>
                                <li>Harmony – Theory and Application</li>
                            </h4>
                            <h4>
                                <li>Exercises to Develop Range and Tonal Quality.</li>
                            </h4>
                            <h4>
                                <li>Basic Music Theory</li>
                            </h4>
                            <h4>
                                <li>Introduction to Pitch, Rhythm And Harmony</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img20} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Music Classes</li>
                        </h2>

                        <h2 className="contectPara">Releasing stress through the power of music</h2>

                        <h3>Music can have a profound effect on both the emotions and the body. Faster music can make
                            you feel more alert and concentrate better. Upbeat music can make you feel more optimistic
                            and positive about life. </h3>

                        <ul>
                            <h4>
                                <li>Flute</li>
                            </h4>
                            <h4>
                                <li>Guitar</li>
                            </h4>
                            <h4>
                                <li>Cello</li>
                            </h4>
                            <h4>
                                <li>Drums</li>
                            </h4>
                            <h4>
                                <li>Bugle</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img21} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Dance Classes</li>
                        </h2>

                        <h2 className="contectPara">Dance is a physical expression of emotion</h2>

                        <h3>Dance is a powerful impulse, but the art of dance is that impulse channelled by skilful
                            performers into something that becomes intensely expressive and that may delight spectators
                            who feel no wish to dance themselves.</h3>
                        <ul>
                            <h4>
                                <li>Ballet</li>
                            </h4>
                            <h4>
                                <li>Ballroom</li>
                            </h4>
                            <h4>
                                <li>Contemporary</li>
                            </h4>
                            <h4>
                                <li>Hip Hop</li>
                            </h4>
                            <h4>
                                <li>Jazz</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img22} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Acting Classes</li>
                        </h2>

                        <h2 className="contectPara">Are you acting how you can express your emotion?</h2>

                        <h3>Acting is an activity in which a story is told by means of its enactment by an actor or
                            actress who adopts a character—in theatre, television, film, radio, or any other medium that
                            makes use of the mimetic mode.</h3>
                        <ul>
                            <h4>
                                <li>Classical Acting</li>
                            </h4>
                            <h4>
                                <li>Modern Acting</li>
                            </h4>
                            <h4>
                                <li>Method Acting</li>
                            </h4>
                            <h4>
                                <li>The Mainer Technique</li>
                            </h4>
                            <h4>
                                <li>The Chekhov Technique</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img23} alt=""/>

                    </div>
                </div>
            </div>
            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Art Director</li>
                        </h2>

                        <h2 className="contectPara">Leading A team of Artists</h2>

                        <h3>Art director is the title for a variety of similar job functions in theatre, advertising,
                            marketing, publishing, fashion, film and television, the Internet, and video games. It is
                            the
                            charge of a sole art director to supervise and unify the vision of an artistic production.
                        </h3>
                        <ul>
                            <h4>
                                <li>Determine how best to represent a concept visually</li>
                            </h4>
                            <h4>
                                <li>Determine which photographs, art, or other design elements to use</li>
                            </h4>
                            <h4>
                                <li>Develop the overall look or style of a publication, an advertising campaign, or a
                                    theatre, television, or film set</li>
                            </h4>
                            <h4>
                                <li>Manage graphic designers, set and exhibit designers, or other design staff</li>
                            </h4>
                            <h4>
                                <li>Review and approve designs, artwork, photography, and graphics developed by other
                                </li>
                                </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img24} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Photography</li>
                        </h2>

                        <h2 className="contectPara">The art, practice, or occupation of taking and printing photographs</h2>

                        <h3>Photography is the art, application, and practice of creating durable images by recording
                            light, either electronically by means of an image sensor, or chemically by means of a
                            light-sensitive material such as photographic film.</h3>
                        <ul>
                            <h4>
                                <li>Portrait Photography</li>
                            </h4>
                            <h4>
                                <li>Fashion Photography</li>
                            </h4>
                            <h4>
                                <li>Sports Photography</li>
                            </h4>
                            <h4>
                                <li>Still Life Photography</li>
                            </h4>
                            <h4>
                                <li>Architectural Photography</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img25} alt=""/>

                    </div>
                </div>
            </div>
            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Graphic Design</li>
                        </h2>

                        <h3>Graphic design is a craft where professionals create visual content to communicate messages.
                            By applying visual hierarchy and page layout techniques, designers use typography and
                            pictures to meet users' specific needs and focus on the logic of displaying elements in
                            interactive designs, to optimize the user experience.</h3>
                        <ul>
                            <h4>
                                <li>Technical sketching</li>
                            </h4>
                            <h4>
                                <li>Photoshop</li>
                            </h4>
                            <h4>
                                <li>Illustrator</li>
                            </h4>
                            <h4>
                                <li>Corel Draw</li>
                            </h4>
                            <h4>
                                <li>Premiere pro</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img26} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Digital Marketing</li>
                        </h2>

                        <h3>At a high level, digital marketing refers to advertising delivered through digital channels
                            such as search engines, website, social media, email, and mobile Apps . Using these online
                            media channels, digital marketing is the method by which companies endorse goods, services,
                            and brands.</h3>
                        <ul>
                            <h4>
                                <li>Website Planning and Creation</li>
                            </h4>
                            <h4>
                                <li>Search Engine Optimisation (SEO)</li>
                            </h4>
                            <h4>
                                <li>Search Engine Marketing</li>
                            </h4>
                            <h4>
                                <li>Social Media Marketing</li>
                            </h4>
                            <h4>
                                <li>Web Analytics</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img27} alt=""/>

                    </div>
                </div>
            </div>
            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Drawing panting/ sketching</li>
                        </h2>

                        <h3>Drawing is one of the oldest forms of human expression within the visual arts. It is
                            generally concerned with the marking of lines and areas of tone onto paper/other material,
                            where the accurate representation of the visual world is expressed upon a plane surface.
                        </h3>

                        <ul>
                            <h4>
                                <li>Illustration Drawing </li>
                            </h4>
                            <h4>
                                <li>Emotive Drawing</li>
                            </h4>
                            <h4>
                                <li>Perspective Drawing</li>
                            </h4>
                            <h4>
                                <li>Diagrammatic Drawing </li>
                            </h4>
                            <h4>
                                <li>Geometric Drawing</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img28} alt=""/>

                    </div>
                </div>
            </div>
        </ol>
        <div className="gap-1"></div>
    </div>

    <div className="container bg-light">
        <h1 id="spiritual" className="Display-2"><strong>Spiritual</strong></h1>
        <hr/>
        <ol>
            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className="contentHeading">
                            <li>Chakra Healing</li>
                        </h2>

                        <h2 className="contectPara"> The scientific way</h2>
                        <ul>
                            <h4>
                                <li>What are Chakras?</li>
                            </h4>
                            <h4>
                                <li>Understanding how chakras effect our decision making.</li>
                            </h4>
                            <h4>
                                <li>How to balance them to live a blissful life.</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid content_img" src={img29} alt=""/>

                    </div>
                </div>
            </div>

            <div className="gap-1"></div>
            <div className="gap-1"></div>

            <div className="card border-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-2">
                        <h2 className="contentHeading">
                            <li>Adhik Mass Poornima</li>
                        </h2>
                        <ul>
                            <h4>
                                <li>Micro yoga</li>
                            </h4>
                            <h4>
                                <li>Yoga for wight lose</li>
                            </h4>
                            <h4>
                                <li>Yoga for endocrine system</li>
                            </h4>
                            <h4>
                                <li>Yoga for nerve system</li>
                            </h4>
                            <h4>
                                <li>Yoga nindra</li>
                            </h4>
                            <h4>
                                <li>Pranyam</li>
                            </h4>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                        <img className="img-fluid content_img" src={img30} alt=""/>

                    </div>
                </div>
            </div>
        </ol>
        <div className="gap-1"></div>
    </div>
    <div className="container bg-light">
        <h1 id="womenCentric" className="Display-2"><strong>Women Centric</strong></h1>
        <hr/>
        <div className="container">
            <img className="img-fluid" style={{height: '80vh', width: "100%"}} src={img31}
                alt=""/>
        </div>
        <div className="container text-center">
            <h2 className="contentHeading">
                THE SEXUAL HARASSMENT OF WOMEN at Workplace Act 2013
            </h2>

            <h2 className="contectPara">(Prevention , Protection and Redressal)</h2>
        </div>
        <div className="container">
            <h3 className="bg-danger" style={{borderRadius: "5px"}}>Overview of the Workshop: </h3>
            <ul>
                <h4>
                    <li>Gender Sensitivity</li>
                </h4>
                <ul>
                    <h5>
                        <li>Examples of women breaking stereotype</li>
                    </h5>
                </ul>
                <h4>
                    <li>Sexual Harassment Act (POSH/ SHOW)</li>
                </h4>
                <ul>
                    <h5>
                        <li>Background</li>
                    </h5>
                    <h5>
                        <li>Vishaka Guidelines</li>
                    </h5>
                    <h5>
                        <li>When was it enacted and came in force</li>
                    </h5>
                    <h5>
                        <li>Definition and what constitutes Sexual Harassment</li>
                    </h5>
                    <h5>
                        <li>Forms of Sexual Harassment</li>
                    </h5>
                    <h5>
                        <li>Scenarios</li>
                    </h5>
                </ul>
                <h4>
                    <li>Scenarios</li>
                </h4>
                <h4>
                    <li>Internal Committee</li>
                </h4>
                <h4>
                    <li>Local Committee</li>
                </h4>
                <h4>
                    <li>Who can complaint and where</li>
                </h4>
                <h4>
                    <li>What should the complaint contain</li>
                </h4>
                <h4>
                    <li>How to complaint</li>
                </h4>
                <h4>
                    <li>Inquiry Process</li>
                </h4>
                <h4>
                    <li>Rights of Complainant</li>
                </h4>
                <h4>
                    <li>Rights of Respondent</li>
                </h4>
            </ul>
        </div>
        <div className="gap-1"></div>
        <div className="gap-1"></div>
    </div>
    <div className="container bg-light">
        <h1 id="personalCoaching" className="Display-2"><strong>Personal Coaching</strong></h1>
        <hr/>
        <div className="container">
            <img className="img-fluid" style={{height: '80vh', width: '100%'}} src={img32}
                alt=""/>
        </div>
        <div className="container text-center">
            <h2 className="contentHeading">
                LIFE COACHING SERVICES
            </h2>

            <h2 className="contectPara">Explore our exclusive one to one and group life coaching services to help you live
                life with courage, passion, meaning, and significance.</h2>
        </div>
        <div className="gap-1"></div>
        <div className="container">
            <h3 style={{borderRadius: "5px"}}>With experience and observations for more than decade, I came out with a few
                major areas where people are looking for help.</h3>
            <ul>
                <h4>
                    <li>Exclusive Coaching for Introverts</li>
                </h4>
                <h4>
                    <li>Relationship Coaching</li>
                </h4>
                <h4>
                    <li>Self-Esteem Coaching</li>
                </h4>
                <h4>
                    <li>Passion Coaching</li>
                </h4>
                <h4>
                    <li>Transformational Coaching</li>
                </h4>
                <h4>
                    <li>Performance Coaching</li>
                </h4>
                <h4>
                    <li>Communication Coaching</li>
                </h4>
            </ul>
        </div>
        <div className="gap-1"></div>
        <div className="gap-1"></div>
    </div>


        <Footer/>
    </div>
  )
}

export default Effectiveness