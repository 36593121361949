import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/events/Engagement-Banner (Landscape)-2.jpg'
import img1 from '../Assets/events/Engagement-Banner (Mobile View).jpg'
import img2 from '../Assets/events/Herok Pal.png'
import img3 from '../Assets/events/Vibha Nasheen.png'
import img4 from '../Assets/events/Arun Prabhu.png'

const StoryWorkshop = () => {
    return (
        <div>
            <Header />
            <div className="bg-light">
                <div className="image">
                    <div className="main-image d-none d-md-block">
                        <img src={img} className="img-fluid" alt="" />
                    </div>
                    <div className="mobile-main-image d-block d-md-none">
                        <img src={img1} className="img-fluid" alt="" />
                    </div>
                </div>
                <div className="different-story mt-5 mx-4" >
                    <div className="d-md-flex d-sm-block" style={{gap:'20px'}}>
                        <div className="w-100 story">
                            <h2 className="text-center mb-3">Kids Storytelling</h2>
                            <p className="pp" align="justify">Storytelling is the oldest form of teaching and communication to
                                children. It gives them the opportunity to grasp new ideas and
                                information and valuable life lessons by way of an engaging
                                and exciting story.</p>
                            <ul className="font">
                                <li>Promote brain development and imagination</li>
                                <li>Boost confidence</li>
                                <li>Improve communication skills</li>
                                <li>Make learning easier</li>
                                <li>Improve social skills</li>
                                <li>Engage creative side</li>
                                <li>Increase cultural understanding</li>
                            </ul>

                            <a href="./story_telling" className="btn btn-outline-primary  me-2">Know more</a>
                            <a href="/register" className="btn btn-danger ">Book seat</a>
                        </div>
                        <hr className="d-block d-sm-none" />

                        <div className="w-100 story me-3">
                            <h2 className="text-center mb-3">Corporate Storytelling</h2>
                            <p className="pp" align="justify">The practice of Corporate storytelling narrative techniques to
                                business practices. You can use it to convey company values,
                                inspire, emotional and create personal bond with customers,
                                colleagues or stakeholders</p>
                            <ul className="font">
                                <li>Improves communication</li>
                                <li>Increases engagement</li>
                                <li>Distinguishes a company's brand</li>
                                <li>Makes information memorable</li>
                                <li>Establishes team morale</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className="container">

                    <div className="main-heading w-50 text-center mx-auto mt-5">
                        <h1 className=" heading">Our Panel of Storytellers</h1>
                        <hr className="mx-auto" style={{margin: '0', padding:'0', width: "80%"}} />
                    </div>
                    <div className="pannel bg-light p-3 w-75 mx-auto mt-5">
                        <div className="row mt-4">
                            <div className="col-md-4 text-center order-md-1">
                                <img src={img2} className="img-fluid " alt="" style={{width: "300"}} />
                            </div>
                            <div className="col-md-8 order-md-2">
                                <div className="d-flex flex-column justify-content-center  col-center">
                                    <h2 className="purple">HEROK PAL</h2>
                                    <p className="pp" align="justify">Herok is a certified Storyteller and has been pursuing his
                                        passion since 2018. He collaborated with various NGOs,
                                        schools and colleges where he performed and created
                                        stories with like minded people. Herok has performed at
                                        Tedx Parichowk and is also a renowned Youtuber.</p>
                                    <a href="./herokpal">View profile</a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-4 text-center order-md-2">
                                <img src={img3} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-8 order-md-1">
                                <div className="d-flex flex-column justify-content-center  col-center">
                                    <h2 className="name purple">VIBHA NASHEEN</h2>
                                    <p className="pp" align="justify">Vibha is an inspirational speaker, storyteller and a life coach.
                                        She is actively involved with kids of different age groups
                                        through various activities like story telling, counselling,
                                        workshops on emotional freedom, leadership skills etc. She
                                        is also a published author and a biking enthusiast.</p>
                                        <a href="./vibhanasheen">View profile</a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-4 text-center order-md-1 cards">
                                <img src={img4} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-8 order-md-2">
                                <div className="d-flex flex-column justify-content-center  col-center">
                                    <h2 className="purple">ARUN PRABHU</h2>
                                    <p className="pp" align="justify">Arun Prabhu is an Entrepreneur, Trainer, Motivator and
                                        a corporate story teller. He has vast experience in
                                        training people through story telling for all ages He is
                                        also a published author and a theatre enthusiast.</p>
                                    <a href="./arunprabhu">View profile</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default StoryWorkshop