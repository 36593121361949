import React from 'react'
import img1 from '../Assets/sartrac-default.jpg'
import img2 from '../Assets/virasat-banner.png'

const HeaderBanner = () => {
    return (
        <div><div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="false">

            <div className="carousel-inner">

                <div className="carousel-item d-none d-md-block">
                    <img src={img1} className="d-block w-100" alt="..." />
                </div>
                <div className="d-block d-md-none">
                    <img src={img2} className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-block d-md-none">
                        <a className="btn btn-primary" href="/virasat-2023" target="_blank">Book Now</a>
                    </div>
                </div>

            </div></div>
        </div>
    )
}

export default HeaderBanner;