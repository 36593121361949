import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/Herok Pal-Banner (1300X500).jpg'
import img1 from '../Assets/events/herok.jpg'

const HerokPal = () => {
    return (
        <div>
            <Header />
            <div className="bg-light">
                <div className="">
                    <div className="">
                        <div className="">
                            <img className="img-fluid header-image" src={img} />
                        </div>
                    </div>
                </div>
                <div className="gap-1"></div>
                <div className="container-fluid">
                    <div className=" text-center">
                        <div className="">
                            <h1 className="display-6">HEROK PAL</h1>
                        </div>
                    </div>
                    <div className="gap-1"></div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6 text-center order-md-2">
                            <img className="img-fluid rounded-circle shadow " style={{ width: '15rem', height: '15rem', objectFit: 'cover', border: "3px solid #531a51" }} src={img1} />
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 order-md-1">
                            <p align="justify">Herok is a Certified Storyteller. He learnt this art from his Guru Ms. Deepti Pant in 2018, and since then he has been pursuing his passion wholeheartedly. Herok has performed in various Schools, NGOs, Colleges and Corporates.</p>
                            <p align="justify">In 2019, he was a part of a Storytelling television show named "Kahaniwala and Sons". Unfortunately, the show had to shut down due to COVID, but that never discouraged him and he continued his journey and became a passionate Storyteller, a TEDx Performer and also participated in Kalamanthan literature festival and The Outsider Moving Art & Film Festival.</p>
                            <p><a href="https://www.youtube.com/channel/UCLXEG13U37pem0cwXy6lz9w"><strong>Click here</strong></a> to watch some of his work on YouTube.</p>
                        </div>
                    </div>
                    <h3 className="text-center mb-4 mt-4">List of works</h3>
                    <ul>
                        <li>
                            <p className="m-0 p-0">Kids Storytelling performed for Kiran Nadar Museum of Art (May 2020)</p>
                            <a href="https://www.knma.in/story-week-children-0">https://www.knma.in/story-week-children-0</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">Story on scrap picker - Delite Grand Annual Function in Faridabad (May 2019)</p>
                            <a href="https://www.youtube.com/watch?v=tXQ3g0S_ngY">https://www.youtube.com/watch?v=tXQ3g0S_ngY</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">Storytelling with Hers is Ours NGO for art and film festival - 2021</p>
                            <a href="https://lifestyle.livemint.com/news/talking-point/a-festival-that-uses-art-to-start-conversations-111638064329915.html">https://lifestyle.livemint.com/news/talking-point/a-festival-that-uses-art-to-start-conversations-111638064329915.html</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">Story on Acid attack - Nehru Place (March 2019)</p>
                            <a href="https://timesofindia.indiatimes.com/city/delhi/whats-you-story-a-storytelling-event-held-at-nehru-place/articleshow/68546801.cms">https://timesofindia.indiatimes.com/city/delhi/whats-you-story-a-storytelling-event-held-at-nehru-place/articleshow/68546801.cms</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">"Jinn" A story on Mental health issues with FnP Media (July 2021)</p>
                            <a href="https://www.youtube.com/watch?v=MgH3BtzxgC0">https://www.youtube.com/watch?v=MgH3BtzxgC0</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">"Khol do" – A story narrated on Partition. One of the famous story writer – Saadat Hasan Manto (collaborate with FnP Media) (Oct 2019)</p>
                            <a href="https://www.youtube.com/watch?v=MgP2RKyIp-Y">https://www.youtube.com/watch?v=MgP2RKyIp-Y</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">"Vineeta"- A story on Transgender life (Jul 2019)</p>
                            <a href="https://www.youtube.com/watch?v=TOtx7JMrUa0">https://www.youtube.com/watch?v=TOtx7JMrUa0</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">Interview with Khayaalon ki Udaan (May 2020)</p>
                            <a href="https://youtu.be/UcZjAbamitE">https://youtu.be/UcZjAbamitE</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">Performed Mahabharat story on Kalamanthan Literature festival</p>
                            <a href="https://youtu.be/k3lOnlNNULQ">https://youtu.be/k3lOnlNNULQ</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">Story on Global Warming on Your Voice</p>
                            <a href="https://youtu.be/6xAddenFAak">https://youtu.be/6xAddenFAak</a>
                        </li>
                        <li>
                            <p className="m-0 p-0">"Duniya Ka Sabse Pehla Artist" – LIVE performance for Wordsutra (2020)</p>
                            <a href="https://www.youtube.com/watch?v=2JAtt1EABZs">https://www.youtube.com/watch?v=2JAtt1EABZs</a>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HerokPal