import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import a from "../Assets/virasat-2023/IMG_0214.JPG";
import b from "../Assets/virasat-2023/IMG_0219.JPG";
import c from "../Assets/virasat-2023/IMG_0298.JPG";
import d from "../Assets/virasat-2023/IMG_0307.JPG";
import e from "../Assets/virasat-2023/IMG_0314.JPG";
import f from "../Assets/virasat-2023/IMG_0332.JPG";
import g from "../Assets/virasat-2023/IMG_0336.JPG";
import h from "../Assets/virasat-2023/IMG_0337.JPG";
import i from "../Assets/virasat-2023/IMG_0338.JPG";
import j from "../Assets/virasat-2023/IMG_0344.JPG";
import k from "../Assets/virasat-2023/IMG_0358.JPG";
import l from "../Assets/virasat-2023/IMG_0423.JPG";
import m from "../Assets/virasat-2023/IMG_0436.JPG";
import n from "../Assets/virasat-2023/IMG_0452.JPG";
import o from "../Assets/virasat-2023/IMG_0461.JPG";
import p from "../Assets/virasat-2023/IMG_0474.JPG";
import q from "../Assets/virasat-2023/IMG_0477.JPG";

import a1 from "../Assets/virasat-2023/IMG_0482.JPG";
import b1 from "../Assets/virasat-2023/IMG_0495.JPG";
import c1 from "../Assets/virasat-2023/IMG_0512.JPG";
import d1 from "../Assets/virasat-2023/IMG_0516.JPG";
import e1 from "../Assets/virasat-2023/IMG_0522.JPG";
import f1 from "../Assets/virasat-2023/IMG_0546.JPG";
import g1 from "../Assets/virasat-2023/IMG_0584.JPG";
import h1 from "../Assets/virasat-2023/IMG_0599.JPG";
import i1 from "../Assets/virasat-2023/IMG_0602.JPG";
import j1 from "../Assets/virasat-2023/IMG_0662.JPG";
import k1 from "../Assets/virasat-2023/IMG_0666.JPG";
import l1 from "../Assets/virasat-2023/IMG_0703.JPG";
import m1 from "../Assets/virasat-2023/IMG_0716.JPG";
import n1 from "../Assets/virasat-2023/IMG_0723.JPG";
import o1 from "../Assets/virasat-2023/IMG_0726.JPG";
import p1 from "../Assets/virasat-2023/IMG_0727.JPG";
import q1 from "../Assets/virasat-2023/IMG_0728.JPG";

import r1 from "../Assets/virasat-2023/IMG_0732.JPG";
import s1 from "../Assets/virasat-2023/IMG_0744.JPG";
import t1 from "../Assets/virasat-2023/IMG_0754.JPG";
import u1 from "../Assets/virasat-2023/IMG_0769.JPG";

import a2 from "../Assets/virasat-2023/IMG_0773.JPG";
import b2 from "../Assets/virasat-2023/IMG_0774.JPG";
import c2 from "../Assets/virasat-2023/IMG_0754.JPG";
import d2 from "../Assets/virasat-2023/IMG_0769.JPG";
import e2 from "../Assets/virasat-2023/IMG_0773.JPG";
import f2 from "../Assets/virasat-2023/IMG_0774.JPG";
import g2 from "../Assets/virasat-2023/IMG_0777.JPG";
import h2 from "../Assets/virasat-2023/IMG_0790.JPG";
import i2 from "../Assets/virasat-2023/IMG_0796.JPG";
import j2 from "../Assets/virasat-2023/IMG_0914.JPG";
import k2 from "../Assets/virasat-2023/IMG_0916.JPG";
import l2 from "../Assets/virasat-2023/IMG_0917.JPG";
import m2 from "../Assets/virasat-2023/IMG_0982.JPG";
import n2 from "../Assets/virasat-2023/IMG_0987.JPG";
import o2 from "../Assets/virasat-2023/IMG_0999.JPG";
import p2 from "../Assets/virasat-2023/IMG_1013.JPG";
import q2 from "../Assets/virasat-2023/IMG_1033.JPG";

import r2 from "../Assets/virasat-2023/IMG_1051.JPG";
import s2 from "../Assets/virasat-2023/IMG_1073.JPG";
import t2 from "../Assets/virasat-2023/IMG_1076.JPG";
import u2 from "../Assets/virasat-2023/IMG_1097.JPG";

import a3 from "../Assets/virasat-2023/IMG_1105.JPG";
import b3 from "../Assets/virasat-2023/IMG_1115.JPG";
import c3 from "../Assets/virasat-2023/IMG_1133.JPG";
import d3 from "../Assets/virasat-2023/IMG_1185.JPG";
import e3 from "../Assets/virasat-2023/IMG_1187.JPG";
import f3 from "../Assets/virasat-2023/IMG_1202.JPG";
import g3 from "../Assets/virasat-2023/IMG_1214.JPG";
import h3 from "../Assets/virasat-2023/IMG_1238.JPG";
import i3 from "../Assets/virasat-2023/IMG_1284.JPG";
import j3 from "../Assets/virasat-2023/IMG_1291.JPG";
import k3 from "../Assets/virasat-2023/IMG_1294.JPG";
import l3 from "../Assets/virasat-2023/IMG_1300.JPG";
import m3 from "../Assets/virasat-2023/IMG_1315.JPG";
import n3 from "../Assets/virasat-2023/IMG_1319.JPG";
import o3 from "../Assets/virasat-2023/IMG_1329.JPG";
import p3 from "../Assets/virasat-2023/IMG_1331.JPG";




const Virasat = () => {
    const images = [
        a,
        b,
        c,
        d,
        e,
        f,
        g,
        h,
        i,
        j,
        k,
        l,
        m,
        n,
        o,
        p,
        q,

        a1,
        b1,
        c1,
        d1,
        e1,
        f1,
        g1,
        h1,
        i1,
        j1,
        k1,
        l1,
        m1,
        n1,
        o1,
        p1,
        q1,
        r1,
        s1,
        t1,
        u1,

        a2,
        b2,
        c2,
        d2,
        e2,
        f2,
        g2,
        h2,
        i2,
        j2,
        k2,
        l2,
        m2,
        n2,
        o2,
        p2,
        q2,
        r2,
        s2,
        t2,
        u2,
        a3,
        b3,
        c3,
        d3,
        e3,
        f3,
        g3,
        h3,
        i3,
        j3,
        k3,
        l3,
        m3,
        n3,
        o3,
        p3,
    ];
    return (
        <div>
            <Header />
            <h1 style={{ textAlign: 'center', margin: '10px auto' }}>
                <u>Virasat 2023</u>
            </h1>

            <div className="container-fluid">
                <div className="row mt-4">
                    {images.map((image, index) => (
                        <div className="item col-sm-6 col-md-3 mb-3" key={index}>
                            <a href={image} className="fancybox" data-fancybox="gallery1">
                                <img
                                    src={image}
                                    width="100%"
                                    className="img-fluid height_set"
                                    alt={`Slide ${index + 1}`}
                                />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default Virasat;
