import React from 'react'
import Header from './Header'
import Footer from './Footer'
import img from '../Assets/naziaparwin.png'
import img1 from '../Assets/nazia.png'

const NaziaParwin = () => {
    return (
        <div>
            <Header />
            <div className="nazia-background">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <img className="img-fluid header-image" src={img} />
                        </div>
                    </div>
                </div>
                <div className="gap-1"></div>
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h1 className="display-6">NAZIA PARWIN</h1>
                        </div>
                    </div>
                    <div className="gap-1"></div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <p align="justify">Nazia dedicated herself to the practice, study and teaching Yoga since discovering its joy and benefits in 2016. She post graduated and has been an applied learner in naturopathy with the desciplines of esteemed institutions of Delhi. Her aspiration evolves with combining elements of style and dynamic postures for the people around. The practices on meditation and teaching has inculcated the awaremenss in Yoga appearing on 'Home Practice' to many people as a spot-selling health and fitness for 4 years. </p>
                            <p align="justify">She co-taught a class of students to the elders, all and whole with Yoga Session promoting</p>
                            <ul>
                                <li>To set expectation</li>
                                <li>To know your audience</li>
                                <li>To let your personality shine</li>
                                <li>To heal the problems with confidence and hope </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 text-center">
                            <img className="img-fluid vert-move" src={img1} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NaziaParwin