import React, { useState } from "react";
import image from '../Assets/sartrac-logo.png'
import { RiMenuFold3Fill } from "react-icons/ri";

const Header = () => {


    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <a className="navbar-brand img-fluid" href="./index.php"><img className='img-fluid' style={{ height: '30px' }} src={image} /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <RiMenuFold3Fill />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-auto" style={{ gap: '10px' }}>
                            <li className="nav-item">
                                <a className="nav-link" href="./"><i className="fas fa-home"></i>{' '}Home</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-info-circle"></i>{' '}About us
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="./sartrac">Sartrac Global</a></li>
                                    <li><a className="dropdown-item" href="./effectiveness">From Effectiveness To Greatness</a></li>
                                    <li><a className="dropdown-item" href="./arunprabhu">Arun Prabhu</a></li>
                                    <li><a className="dropdown-item" href="./naziaparwin">Nazia Parwin</a></li>
                                    <li><a className="dropdown-item" href="./maheshsharma">Mahesh Sharma</a></li>
                                    <li><a className="dropdown-item" href="./vibhanasheen">Vibha Nasheen</a></li>
                                    <li><a className="dropdown-item" href="./herokpal">Herok Pal</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="./membership"><i className="fas fa-stamp"></i>{' '}Membership Programs</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fab fa-staylinked"></i>{' '}Engagements
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                    <li><a className="dropdown-item" href="./story-workshop">Story Telling</a></li>
                                    <li><a className="dropdown-item" href="./theater">Oh-fish Office</a></li>
                                    <li><a className="dropdown-item" href="./independance-day-2022">Independance Day Contest 2022</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="./news"><i className="fas fa-newspaper"></i>{' '}News</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="./gallery"><i className="fas fa-users"></i>{' '}Gallery</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="./registration"><i className="fas fa-user-plus"></i>{' '}Register</a>
                            </li>
                            {/* <li className="nav-item">
                    <a className="nav-link" href="#"><i className="fas fa-envelope"></i> Contact Us</a>
                </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;
