import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Register = () => {
    return (
        <div>
            <Header />
            <div className="background-pic">
                <div className="container bg-light rounded p-4 mb-3">
                    <form id="redirectForm" method="POST" action="register_inser.php">
                        <div className="form-group mb-3" style={{ display: "none" }}>
                            <label>App ID:</label><br />
                            <input required autocomplete="off" className="form-control" name="appId" value="1674170dbd8f7cc745b4ced18d714761" />
                        </div>
                        <div className="form-group mb-3" style={{ display: "none" }}>
                            <label>Order ID:</label><br />

                            <input required autocomplete="off" className="form-control" name="orderId" value="670f8b72d55b3" />
                        </div>
                        <div className="mb-3">
                            <label for="parentName">Parent / Guardian's Name</label>
                            <input type="text" id="parentName" className="form-control" name="parentName" required />
                        </div>
                        <div className="mb-3">
                            <label for="mobile">Mobile Number</label>
                            <input type="number" id="mobile" className="form-control" name="customerPhone" required />
                        </div>
                        <div className="mb-3">
                            <label for="email">Email ID</label>
                            <input type="email" id="email" className="form-control" name="customerEmail" required />
                        </div>
                        <div className="mb-3">
                            <label for="childrenNumber">Number of Children</label>
                            <input type="number" id="childrenNumber" className="form-control childrenNumber" name="childrenNumber" required />
                        </div>
                        <div className="appending_div">
                        </div>
                        <div className="mb-3">
                            <label for="fees">Fees</label>
                            <input type="number" className="form-control" id="fees" name="orderAmount" readonly required />
                        </div>
                        <div className="form-group mb-3" style={{ display: "none" }}>
                            <label>Order Currency:</label><br />
                            <input type="text" className="form-control" name="orderCurrency" value="INR" placeholder="Enter Currency here" />
                        </div>
                        <div className="form-group mb-3">
                            <label for="reference">Referred By:</label>
                            <select name="reference" className="form-control" id="reference" onchange="friend(this); required">
                                <option value="Select from Dropdown" selected disabled>Select from Dropdown</option>
                                <option value="Social Media">Social Media</option>
                                <option value="Advertisment">Advertisment</option>
                            </select>
                        </div>
                        <div className="form-group mb-3" id="friend-name" style={{ display: "none" }}>
                            <label for="reference_name">Select social media platform</label>
                            <select className="form-control" name="reference_name" id="reference_name">
                                <option value="Facebook">Facebook</option>
                                <option value="Linkedin">Linkedin</option>
                            </select>
                        </div>
                        <div className="form-group mb-3" style={{ display: "none" }}>
                            <label>Return URL:</label><br />
                            <input required autocomplete="off" className="form-control" name="returnUrl" value="https://sartracglobal.com/story-telling/responce.php" />
                        </div>
                        <button type="submit" className="btn btn-success btn-block w-100 mb-3 mt-3" value="Pay">Book Now</button>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Register