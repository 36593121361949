import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import a from "../Assets/theater/oh-fish-office-oct-2023/1.jpg";
import b from "../Assets/theater/oh-fish-office-oct-2023/2.jpg";
import c from "../Assets/theater/oh-fish-office-oct-2023/3.jpg";
import d from "../Assets/theater/oh-fish-office-oct-2023/4.jpg";
import e from "../Assets/theater/oh-fish-office-oct-2023/5.jpg";
import f from "../Assets/theater/oh-fish-office-oct-2023/6.jpg";
import g from "../Assets/theater/oh-fish-office-oct-2023/7.jpg";
import h from "../Assets/theater/oh-fish-office-oct-2023/8.jpg";
import i from "../Assets/theater/oh-fish-office-oct-2023/9.jpg";
import j from "../Assets/theater/oh-fish-office-oct-2023/10.jpg";
import k from "../Assets/theater/oh-fish-office-oct-2023/11.jpg";
import l from "../Assets/theater/oh-fish-office-oct-2023/12.jpg";
import m from "../Assets/theater/oh-fish-office-oct-2023/13.jpg";
import n from "../Assets/theater/oh-fish-office-oct-2023/14.jpg";
import o from "../Assets/theater/oh-fish-office-oct-2023/15.jpg";
import p from "../Assets/theater/oh-fish-office-oct-2023/16.jpg";
import q from "../Assets/theater/oh-fish-office-oct-2023/17.jpg";

import a1 from "../Assets/theater/oh-fish-office-oct-2023-2/1.jpg";
import b1 from "../Assets/theater/oh-fish-office-oct-2023-2/2.jpg";
import c1 from "../Assets/theater/oh-fish-office-oct-2023-2/3.jpg";
import d1 from "../Assets/theater/oh-fish-office-oct-2023-2/4.jpg";
import e1 from "../Assets/theater/oh-fish-office-oct-2023-2/5.jpg";
import f1 from "../Assets/theater/oh-fish-office-oct-2023-2/6.jpg";
import g1 from "../Assets/theater/oh-fish-office-oct-2023-2/7.jpg";
import h1 from "../Assets/theater/oh-fish-office-oct-2023-2/8.jpg";
import i1 from "../Assets/theater/oh-fish-office-oct-2023-2/9.jpg";
import j1 from "../Assets/theater/oh-fish-office-oct-2023-2/10.jpg";
import k1 from "../Assets/theater/oh-fish-office-oct-2023-2/11.jpg";
import l1 from "../Assets/theater/oh-fish-office-oct-2023-2/12.jpg";
import m1 from "../Assets/theater/oh-fish-office-oct-2023-2/13.jpg";
import n1 from "../Assets/theater/oh-fish-office-oct-2023-2/14.jpg";
import o1 from "../Assets/theater/oh-fish-office-oct-2023-2/15.jpg";
import p1 from "../Assets/theater/oh-fish-office-oct-2023-2/16.jpg";
import q1 from "../Assets/theater/oh-fish-office-oct-2023-2/17.jpg";

import r1 from "../Assets/theater/oh-fish-office-oct-2023-2/18.jpg";
import s1 from "../Assets/theater/oh-fish-office-oct-2023-2/19.jpg";
import t1 from "../Assets/theater/oh-fish-office-oct-2023-2/10.jpg";
import u1 from "../Assets/theater/oh-fish-office-oct-2023-2/21.jpg";



const Oh_Fish_Office_October_2023 = () => {
    const images = [
        a,
        b,
        c,
        d,
        e,
        f,
        g,
        h,
        i,
        j,
        k,
        l,
        m,
        n,
        o,
        p,
        q,

        a1,
        b1,
        c1,
        d1,
        e1,
        f1,
        g1,
        h1,
        i1,
        j1,
        k1,
        l1,
        m1,
        n1,
        o1,
        p1,
        q1,
        r1,
        s1,
        t1,
        u1
    ];
    return (
        <div>
            <Header />
            <h1 style={{ textAlign: 'center', margin: '10px auto' }}>
                <u>Oh-Fish Office October 2023</u>
            </h1>

            <div className="container-fluid">
                <div className="row mt-4">
                    {images.map((image, index) => (
                        <div className="item col-sm-6 col-md-3 mb-3" key={index}>
                            <a href={image} className="fancybox" data-fancybox="gallery1">
                                <img
                                    src={image}
                                    width="100%"
                                    className="img-fluid height_set"
                                    alt={`Slide ${index + 1}`}
                                />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default Oh_Fish_Office_October_2023;
